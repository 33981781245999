import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby";
import VideoCard from "../components/tips_tools/tip_video_card"
import VideoModal from "../components/tips_tools/tip_video_modal"
import SearchIcon from '../components/icons/search_icon'
import "../components/tips_tools/tips_tools.css"

const TipsToolsPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      strapiTipsPageFrench {
        title
      }
      allStrapiVideo(filter: {language: {in: ["both", "french"] }}) {
        nodes {
          title
          text
          postedDate
          videoFile{
            localFile {
              publicURL
            }
          }
          thumbnailImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)
  const pageInfo = data.strapiTipsPageFrench
  const videos = data.allStrapiVideo.nodes
  const [modalActive, setModalActive] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(videos[0])
  const [searchData, setSearchData] = useState(videos)
  const clearModalActive = ()=>{
    setModalActive(false)
  }
  const handleVideoClick = (video)=>{
    setCurrentVideo(video)
    setModalActive(true)
  }

  const handleSearch = (e) => {
    const value = e.target.value
    if(value){
      var newVideos = videos.filter((v)=>v.title.toLowerCase().includes(value.toLowerCase()))
      setSearchData(newVideos)
    } else {
      setSearchData(videos)
    }
  }
  
  return (
  <Layout language="french" path={props.path}>
    <SEO language="french" title="Conseils et outils" />
    <VideoModal 
      clearModalActive={clearModalActive} 
      video={currentVideo} 
      active={modalActive}
    />
    <div className="tips-top-section">
      <h1 className="page-title center">{pageInfo.title}</h1>
      <div>
        <input onChange={handleSearch} type="text" />
        <SearchIcon width={"20px"} height={"20px"} />
        <p>{searchData.length} {searchData.length===1?"VIDÉO":"VIDÉOS"}</p>
      </div>
    </div>
    <div className="video-cards-container">
      {searchData.length > 0 ? searchData.map((v)=>{
        return(
          <VideoCard key={v.id} handleVideoClick={handleVideoClick} video={v} />
        )
      }):<p>Aucun résultat de recherche trouvé</p>}
    </div>
  </Layout>
)}

export default TipsToolsPage